<template>
  <el-dialog
    :title="'解説文'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :customClass="isMobile ? 'customWidth' : 'customWidth1'">
    <div>
      <el-card shadow="never" class="box-card">
        <div class="dialog-header">
          解説
        </div>
        <div class="dialog-item" style="white-space:pre-line; word-wrap:break-word;">
          {{data.questionExplanation}}
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        data:[],
        isMobile:false
      }
    },
    methods: {
      init (row) {
        this.visible = true
        this.data = row
        //　端末判断
        let ua = navigator.userAgent.toLowerCase();
        this.isMobile = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1 || ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1;
      }
    }
  }
</script>
<style lang="less">
  .customWidth{
    width: 80% !important;
    min-width: 100px !important;
  }
  .customWidth1{

  }
</style>
<template>
  <div class="result-content">
    <Bread :list="bread"></Bread>
    <div class="result-page-content">
      <el-card slot="header" shadow="never" class="box-card">
        <div slot="header" class="clearfix">
          <span>単元テスト名</span>
        </div>
        <div class="text item">
          {{result.testTitle}}
        </div>
      </el-card>
      <div class="test-table">
        <div class="col-title">
          <div class="content">
            正答率
          </div>
          <div class="content">
            テスト結果
          </div>
        </div>
        <div class="col-content">
          <div class="content">
            {{result.answerCorrectRate}}%
          </div>
          <div class="content">
            {{answerResultDict[result.answerResult]}}
              <div v-if="answerResultDict[result.answerResult]== '不合格'">
                <font color="red"><br>教材一覧に戻り、合格になるまで再受検してください</font>
              </div>
          </div>
        </div>
      </div>
      <el-card slot="header" shadow="never" class="box-card" v-if="result.noPassedAnsweres!== undefined && result.noPassedAnsweres!==null && result.noPassedAnsweres.length>0">
        <div slot="header" class="clearfix center h3">
          <span>解答結果一覧</span>
        </div>
        <div class="text item" v-if="!isMobile">
          <el-table
            :data="result.noPassedAnsweres"
            style="width: 100%"
            :border="true"
            :header-cell-style="{background:'#d4ecf4',color:'black',bold}"
          >
            <el-table-column
              type="index"
              :index="indexMethod"
              label="設問"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="解答"
              align="center"
            >
              <template slot-scope="scope">
                <div style="white-space:pre-wrap; word-wrap:break-word;">{{scope.row.answerContent}}</div>  
              </template>
            </el-table-column>
            <el-table-column              
              label="正解"
              align="center"
            >
              <template slot-scope="scope">
                <div style="white-space:pre-wrap; word-wrap:break-word;">{{scope.row.standardAnswers}}</div>  
              </template>
            </el-table-column>
            <el-table-column
              prop="answerResult"
              label="結果"
              align="center"
              width="100"
            >
              <template slot-scope="scope">    
                <span v-if="scope.row.answerResult==='0'" style="color:#357EC7">正解</span>
                <span v-if="scope.row.answerResult==='1'" style="color:red">不正解</span>
              </template>
            </el-table-column>
            <el-table-column
              label="問題"
              align="center"
              width="100"
            >
            <template slot-scope="scope">          
              <el-button type="text" size="small" @click="questionContentHandle(scope.row)">出題内容</el-button>
            </template>
            </el-table-column>
            <el-table-column
              label="解説"
              align="center"
              width="100"
            >
            <template slot-scope="scope">          
              <el-button type="text" size="small" @click="questionExpanationHandle(scope.row)">解説文</el-button>
            </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="text item" v-if="isMobile">
          <div v-for="(question, index) in result.noPassedAnsweres" :key="index">
            <div class="mobile-header">
              問題{{index + 1}}
            </div>
            <div class="mobile-table">
              <div class="col-title">
                解答
              </div>
              <div class="col-content" style="white-space:pre-line; word-wrap:break-word;">
                {{question.answerContent}}
              </div>
            </div>
            <div class="mobile-table">
              <div class="col-title">
                正解
              </div>
              <div class="col-content" style="white-space:pre-line; word-wrap:break-word;">
                {{question.standardAnswers}}
              </div>
            </div>
            <div class="mobile-table">
              <div class="col-title">
                結果
              </div>
              <div class="col-content">
                <span v-if="question.answerResult==='0'" style="color:#357EC7">正解</span>
                <span v-if="question.answerResult==='1'" style="color:red">不正解</span>
              </div>
            </div>
            <div class="mobile-button">
              <el-button native-type="button" type="success" plain @click="questionContentHandle(question)">出題内容</el-button>
              <el-button native-type="button" type="success" plain @click="questionExpanationHandle(question)">解説文</el-button>
            </div>
          </div>
        </div>
      </el-card>
      <br>
      <el-button native-type="button" class="studypack-button-primary" type="primary" plain @click="goUnit"> 教材一覧に戻る </el-button>
      <el-button v-if="result.answerResult === '1' || studentTeachingMaterialDto.teachingMaterialId === null" style="float:right;" native-type="button" class="studypack-button-primary" type="danger" plain @click="goHome()" target="_blank"> トップページ へ </el-button>
      <el-button v-else style="float:right;" native-type="button" class="studypack-button-primary" type="danger" plain @click="goNextTeachingMaterial()" target="_blank" :disabled="result.answerResult === 1 || studentTeachingMaterialDto.teachingMaterialId === null"> 次の教材を受講する </el-button>
    </div>
    <question-content v-if="questionContentVisible" ref="questionContent"></question-content>
    <question-explanation v-if="questionExplanationVisible" ref="questionExplanation"></question-explanation >
  </div>
</template>

<script>
import Bread from '@/components/Bread.vue';
import { Notification } from 'element-ui';
import request from '@/utils/request';
import QuestionContent from './question-content';
import QuestionExplanation from './question-explanation';



export default {
  name: 'TestResult',
  components: {
    Bread,
  },
  data() {
    return {
      answerResultDict: ['合格', '不合格', '単元テスト終了'],
      result: {},
      tableData: [],
      bread: [],
      studentTeachingMaterialDto: {},
      routerMaterialId: '',
      questionContentVisible: false,
      questionExplanationVisible: false,
      isMobile: false
    };
  },
  components: {
      QuestionContent,
      QuestionExplanation
  },
  methods: {
    goNextTeachingMaterial(){
      const courseId = this.studentTeachingMaterialDto.courseId;
      const subjectId = this.studentTeachingMaterialDto.subjectId;
      const unitId = this.studentTeachingMaterialDto.unitId;
      const materialId = this.studentTeachingMaterialDto.teachingMaterialId;
      const testMoveFlag = this.studentTeachingMaterialDto.testMoveFlag;

      if(testMoveFlag){
        this.$router.push({
          path: `/test?courseId=${courseId}&subjectId=${subjectId}&unitId=${unitId}`,
          query: { materialId: materialId }
        }).catch(() => {});
      }else{
        if(this.studentTeachingMaterialDto.contentType === "01"){
          this.$router.push({
          path: `/showVideo/${courseId}/${subjectId}/${unitId}/${materialId}`,
        }).catch(() => {});
        }else if(this.studentTeachingMaterialDto.contentType === "03") {
          this.$router.push({
            path: `/showPDF/${courseId}/${subjectId}/${unitId}/${materialId}`,
          }).catch(() => {});
        }else if(this.studentTeachingMaterialDto.contentType === "08") {
          this.$router.push({
            path: `/showHtml/${courseId}/${subjectId}/${unitId}/${materialId}`,
          }).catch(() => {});
        }
      }
    },
    goUnit() {
      const { courseId, subjectId,unitId} = this.$route.query;
      this.$router.push({
        path: `/unit/${this.result.unitId}/${courseId}/${subjectId}`,
      }).catch(() => {});
    },
    goHome(){
      this.$router.push({
        path: '/',
      }).catch(() => {});
    },
    indexMethod(index) {
      return '問題' + (index + 1);
    },
    questionContentHandle(row){
      this.questionContentVisible = true
      this.$nextTick(() => {       
        this.$refs.questionContent.init(row)
      })
    },
    questionExpanationHandle(row){
      this.questionExplanationVisible = true
      this.$nextTick(() => {       
        this.$refs.questionExplanation.init(row)
      })
    },
  },
  created() {
    this.routerMaterialId = this.$route.query.materialId
    // if(this.routerMaterialId !==undefined){
    request('getTeachingMaterialByCourseList', {
      courseId : this.$route.query.courseId,
      subjectId :  this.$route.query.subjectId,
      unitId :  this.$route.query.unitId,
      materialId :  this.$route.query.materialId,
      testMoveFlag: true
    }).then((res) => {
      this.studentTeachingMaterialDto = res
    });
    // }
    if (!window.testResult) {
      Notification({
        title: 'エラー',
        message: 'No data',
        duration: 0,
        offset: 75,
      });
    } else {
      this.result = window.testResult;
      try{
      this.tableData = this.result.noPassedAnsweres.filter((item) => item.answerResult === '1');
      } catch (e){
        //
      }
      this.bread = [
        {
          path: '/',
          name: 'トップページ',
        },
        {
          path: `/course/${this.result.courseId}`,
          name: this.result.courseName,
        },
        {
          path: `/subject/${this.result.subjectId}/${this.result.courseId}`,
          name: this.result.subjectName,
        },
        {
          path: `/unit/${this.result.unitId}/${this.result.courseId}/${this.result.subjectId}`,
          name: this.result.unitName,
        },
        {
          name: this.result.testName,
        },
      ];
    }
    //　端末判断
    let ua = navigator.userAgent.toLowerCase();
    this.isMobile = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1 || ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1;
  }
};
</script>

<style lang="less">
@import "../styles/test.less";
@import "../styles/mediaQuery.less";
.result-page-content {
  padding-bottom: 30px;
}
.result-table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid @lightBorderColor;
  td {
    border-bottom: 1px solid @lightBorderColor;
    border-right: 1px solid @lightBorderColor;
    padding: 20px;
  }
  .bg {
    background: @tableBg;
  }
  .rowspan-td {
    min-width:30px;
    text-align: center;
    position: relative;
    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.test-table {
  margin: 30px 0;
  display: flex;
  overflow: hidden;
  border: 1px solid @lightBorderColor;
  .col-title {
    flex: 0 0 30%;
    white-space: nowrap;
    background-color: @tableBg;
  }
  .col-order {
    flex: 0 0 10%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @tableBg;
  }
  .content {
    margin: -1px;
    padding: 20px;
    border: 1px solid @lightBorderColor;
    border-right: 0;
  }
  .col-content {
    flex: 1;
  }
}
.el-card__header {
  background: #F5F7FA;
  .center {
    text-align: center;
  }
  .h3 {
    font-size: 20px;
  }
}

.grid-content {
  padding: 20px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.text-block {
  white-space: pre-wrap;
}
.result-content {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding:40px 16px;
  padding-top: 0;
}

.mobile-header {
  padding: 18px 0;
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5; 
  border-right: 1px solid #EBEEF5;
  padding-left: 1em;
  background: #cccccc;
}
.mobile-table {
  display: flex;
  overflow: hidden;
  border: 1px solid @lightBorderColor;
  .col-title {
    flex: 0 0 30%;
    white-space: nowrap;
    background-color: #d4ecf4;
    margin: -1px;
    padding: 20px;
    border: 1px solid @lightBorderColor;
    border-right: 0;
  }
  .col-order {
    flex: 0 0 10%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @tableBg;
  }
  .content {
    margin: -1px;
    padding: 20px;
    border: 1px solid @lightBorderColor;
    border-right: 0;
  }
  .col-content {
    flex: 1;
    margin: -1px;
    padding: 20px;
    border: 1px solid @lightBorderColor;
    border-right: 0;
  }
}
.mobile-button{
  padding: 10px 0;
  display: flex;
  justify-content:space-around;
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5; 
  border-bottom: 1px solid #EBEEF5;
}
</style>

<template>
  <el-dialog
    :title="'出題内容'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :customClass="isMobile ? 'customWidth' : 'customWidth1'">
    <div>
      <el-card shadow="never" class="box-card">
        <div class="dialog-header">
          問題文
        </div>
        <div class="dialog-item" style="white-space:pre-line; word-wrap:break-word;">
          {{data.questionContent}}
        </div>
      </el-card>
      <el-card shadow="never" class="box-card" v-if="data.choicesDtoList">
        <div class="dialog-header">
          選択肢
        </div>
        <div class="dialog-item-choose">
          <div v-for="item in data.choicesDtoList" :key="item" class="dialog-item1" style="white-space:pre-wrap; word-wrap:break-word;">
            {{item}}      
          </div>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        data:[],
        isMobile:false
      }
    },
    methods: {
      init (row) {
        this.visible = true
        this.data = row
        //　端末判断
        let ua = navigator.userAgent.toLowerCase();
        this.isMobile = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1 || ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1;
      }
    }
  }
</script>
<style lang="less">

  .dialog-header {
    padding: 18px 0;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5; 
    border-right: 1px solid #EBEEF5;
    padding-left: 1em;
    background: #d4ecf4;
  }
  .dialog-item {
    padding: 18px 0;
    border: 1px solid #EBEEF5;
    padding-left: 2em;
  }
  .dialog-item-choose {
    padding: 18px 0;
    border: 1px solid #EBEEF5;
    padding-left: 2em;
    padding-right: 2em;
  }
  .dialog-item1 {
    margin: -1px 0 0 -1px;
    padding: 18px 0;
    border: 1px solid #EBEEF5;
    padding-left: 1em;
  }
  .customWidth{
    width: 80% !important;
    min-width: 100px !important;
  }
  .customWidth1{

  }
</style>